.app-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #262F3A;
  z-index: 0;
}

.bg-ellipsis {
  position: absolute;
  width: 68.54%;
  height: 0;
  max-width: 621px;
  opacity: 0.3;
  filter: blur(100px);
  backdrop-filter: blur(100px);
  transform: rotate(-13.86deg);
  z-index: 0;
  
  .ellipsis {
    padding-top: 78.54%;
  }

  &.el-1 {
    top: -183px;
    left: 10.62%;
    opacity: 0.5;

    .ellipsis {
      background-color: #1B5CA0;
    }
  }
  &.el-2 {
    top: 12px;
    left: 30.97%;
    .ellipsis {
      background-color: #2D42B3;
    }
  }
  &.el-3 {
    top: 191px;
    left: 11.25%;

    .ellipsis {
      background-color: #2D9AB3;
    }
  }
  &.el-4 {
    width: 79.79%;
    bottom: 272px;
    left: 16.59%;
    opacity: 0.5;
    transform: rotate(0deg);

    .ellipsis {
      padding-top: 101.82%;
      background-color: #1B5CA0;
    }
  }
  &.el-5 {
    bottom: 298px;
    left: 37.36%;
    transform: rotate(0deg);
    
    .ellipsis {
      background-color: #2D9AB2;
    }
  }

  &.top {
    width: 28.95%;
    left: 50%;
    transform: rotate(0deg) translateX(-50%);
    top: -92px;
    opacity: 0.4;
    .ellipsis {
      padding-top: 100%;
      background-color: #1B5CA0;
    }
  }
  
  .ellipsis {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}