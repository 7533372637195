.content-section.top {
  position: relative;
  margin-bottom: 227px;

  .content-container.top {
    margin-right: 92px;

    .content-title.top {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .content-section.top {
    margin-bottom: 120px;
    .content-container.top {
      margin-right: 0;
  
      .content-title.top {

      }
    }
  } 
}