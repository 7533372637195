.emoji-img {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .emoji-img {
    margin-bottom: 8px;
  } 
}