.content-section.section-footer {
  margin-bottom: 275px;

  .content-container.section-footer {
    margin-right: 170px;
    .content-title.section-footer {
      margin-bottom: 24px;
    }
    .content-description {
      margin-bottom: 28px;
    }

    .app-link-container {
      display: flex;
      align-items: center;
      .web-link.section-footer {
        color: #FFFFFF;
        border-color: #FFFFFF;
        margin-right: 12px;
        max-width: 109px;
      }
      .store-link.section-footer {
        height: 36px;

        &.app-store {
          max-width: 108px;
        }
      
        &.play-store {
          max-width: 121.5px;
        }
        &.app-store {
          margin-right: 12px;
        }
      }
    }
  }
}

.footer-desc.section-footer {
  width: calc(100% - 32px);
  max-width: 960px;
  margin: 0 auto 120px;
  position: relative;
  z-index: 10;
  text-align: center;

  .description.section-footer {
    font-size: 36px;
    font-weight: bold;
    white-space: pre-wrap;
    margin-bottom: 40px;
  }
  .store-link {
    margin-bottom: 12px;
  }
}


@media only screen and (max-width: 1000px) {
  .content-section.section-footer {

    .content-container.section-footer {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer-desc.section-footer {
    margin-bottom: 95px;
    .description.section-footer {
      font-size: 24px;
    }
  }  
  .mobile-badge-w {
    text-align: center;
    margin-bottom: 21px;
  }
}