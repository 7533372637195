.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
  width: calc(100% - 32px);
  max-width: 960px;
  margin: 0 auto;
  padding-top: 18px;
  
  .header-logo {
    width: 120px;
    height: 24px;
  }
  .header-right {
    display: inline-flex;
    align-items: center;
    .header-text-button-w {
      margin-right: 28px;
      .header-text-button {
        font-weight: 600;
        font-size: 14px;
        &:last-child {
          margin-left: 20px;
        }
        &.tc-b {
          color: #3F9DFF;
        }
      }
    }
  }
}