.main-section {
  position: relative;
  z-index: 10;
  width: calc(100% - 32px);
  max-width: 960px;
  padding: 64px 0 0;
  margin: 0 auto 329px;
  text-align: center;
  .content-header.main {
    margin-bottom: 55px;
    .title.main {
      font-weight: bold;
      font-size: 42px;
      margin-bottom: 8px;
      white-space: pre-wrap;
    }
    .desc.main {
      color: #E5F3FF;
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 32px;
    }
    .store-link {
      margin-bottom: 12px;
    }
  }
  .content-w {
    max-width: 724px;
    margin: 0 auto;
    .content.media {
      position: relative;
      padding-top: 68.25%;
      width: 100%;
      height: 0;
      border: 2px solid #CED8E5;
      background: #262F3A;
      border-radius: 22px;
      
      .video-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-section {
    margin-bottom: 120px;
    .content-header.main {
      .title.main {
        font-size: 30px;
      }
      .desc.main {
        font-size: 17px;
      }
    }
    .content-w {
      max-width: 300px;
      border-radius: 19px;
      .content.media {
        padding-top: 178.07%;
      }
    }
  } 
}