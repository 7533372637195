.app-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  max-width: 960px;
  z-index: 15;
  margin: 0 auto;
  padding-bottom: 47px;
  .app-link-w {
    display: inline-flex;
    align-items: center;
		cursor:pointer;

    .link-icon {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }
	}
  .footer-txt-styles {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-top: 2px;
    color: #7F8FA6;
  }
  .divider-dot {
    margin: 0 4px;
    color: #7F8FA6;
  }
}

@media only screen and (max-width: 600px) {
  .app-footer {
    flex-direction: column;
    .app-footer-l {
      order: 1;
    }
    .app-footer-r {
      order: 0;
      margin-bottom: 15px;
    }
    .footer-txt-styles {
      font-weight: 400;
    }
  }  
}