@charset "utf-8";

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	src:url('https://djf7qc4xvps5h.cloudfront.net/resource/font/pretendard/Pretendard-Regular.woff') format('woff');
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	src:url('https://djf7qc4xvps5h.cloudfront.net/resource/font/pretendard/Pretendard-Medium.woff') format('woff');
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	src:url('https://djf7qc4xvps5h.cloudfront.net/resource/font/pretendard/Pretendard-SemiBold.woff') format('woff');
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	src:url('https://djf7qc4xvps5h.cloudfront.net/resource/font/pretendard/Pretendard-Bold.woff') format('woff');
	font-display: swap;
}

* {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
  box-sizing: border-box;
}

html {
  body {
		color: #FFFFFF;
		font-family: 'Pretendard';
  }
}

button {
	appearance: none;
	-webkit-appearance: none;
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	color: inherit;
	font: inherit;
	line-height: normal;
}

img, svg{
	vertical-align: top;
}

span {
	display: inline-block;
	vertical-align:top;
}
section {
	vertical-align:top;
}

span {
  display: inline-block;
  vertical-align: top;
}
textarea {
  resize: none;
  border: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}
a {
	color:inherit;
	text-decoration:none;
	cursor:pointer;
	outline : none;
}

ul, li {
	list-style: none;
}