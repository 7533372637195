.content-section.mid {
  margin-bottom: 236px;

  .content-container.mid {
    margin-right: 89px;

    .content-title.mid {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .content-section.mid {
    margin-bottom: 120px;
  
    .content-container.mid {
      margin-right: 0;
  
      .content-title.mid {

      }
    }
  }
}