.content-section.review {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin-bottom: 110px;
  overflow: hidden;
  margin-bottom: 120px;
  
  .review-list-gradient {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 10;
    &.left {
      left: 0;
      background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #262F3A 100%);
    }
    &.right {
      right: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #262F3A 100%);
    }
  }
  .review-list-viewport {
    width: 100%;
    display: flex;
    justify-content: center;

    &:first-child {
      animation: move-first-list 60s linear infinite;
    }

    @keyframes move-first-list {
      25% {
        transform: translateX(-130px);
      }
      75% {
        transform: translateX(130px);
      }
    }

    &:last-child {
      animation: move-seconds-list 60s linear infinite;
    }

    @keyframes move-seconds-list {
      25% {
        transform: translateX(130px);
      }
      75% {
        transform: translateX(-130px);
      }
    }

    &:not(:last-child) {
      margin-bottom: 28px;
    }
    .review-list {
      display: flex;
      width: max-content;
      align-items: stretch;

      .review-item-w {
        width: 230px;
        height: auto;
        padding: 22px 24px 20px;
        border: 2px solid #3B4757;
        border-radius: 20px;
        &:not(:last-child) {
          margin-right: 32px;
        }
        .r-reviewer-info {
          display: flex;
          align-items: center;
          margin-bottom: 19px;
          .r-profile-img-w {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            overflow: hidden;
            background-color: #D9D9D9;
            border-radius: 50%;
            .profile-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .r-name {
            flex: 1;
            font-weight: 600;
            font-size: 17px;
            line-height: 26px;
            max-height: 26px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
          }
          .r-icon {
            width: 24px;
            height: 24px;
            color: #3F9DFF;
          }
        }
        .r-content {
          font-size: 15px;
          line-height: 23px;
          color: #EBEFF8;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .content-section.review {
    margin-bottom: 248px;
  }
};