body {
	overflow-y:scroll;
}
#root {
	width: 100%;
	height: 100%;
	.app {
		position:relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		.app-foreground {
			position: relative;
      width: 100%;
      height: 100%;
      z-index: 10;
		}
	}
}

