.content-section {
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  max-width: 960px;
  margin: 0 auto;
  .content-title {
    font-size: 36px;
    font-weight: bold;
    white-space: pre-wrap;
  }
  .content-description {
    color: #E5F3FF;
    font-weight: 600;
    line-height: 26px;
    font-size: 17px;
  }

  .content-container {
    width: 50%;
  }

  .image-container {
    width: 50%;
    height: 100%;
    z-index: 10;
  }
}

@media only screen and (max-width: 1000px) {
  .content-section {
    flex-direction: column;

    .content-container {
      width: 100%;
      margin-bottom: 36px;
    }
    .image-container {
      width: 100%;
      max-width: 410px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .content-section {
    flex-direction: column;

    .content-container {
      width: 100%;
      margin-bottom: 36px;
      .content-title {
        font-size: 24px;
      }
      .content-description {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .image-container {
      width: 100%;
      max-width: 410px;
    }
  }
}
