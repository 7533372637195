.round-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 18px;
  font-weight: bold;
  cursor: pointer;
  height: 50px;
  font-size: 15px;
  border-radius: 25px;
  color: #FFFFFF;
  background-color: #3F9DFF;
  &.s-xl {
    height: 50px;
    font-size: 15px;
    border-radius: 25px;
  }
  &.s-l {
    height: 36px;
    font-size: 13px;
  }
  &.s-m {
    height: 22px;
    font-size: 11px;
  }
  &.c-p {
    color: #FFFFFF;
    background-color: #3F9DFF;
  }
  &.c-s {
    color: #3F8EFF;
    background-color: #E5F3FF;
  }
  &.c-n {
    color: #B2BDCE;
    background-color: #EBEFF8;
  }
  &.c-tp {
    color: #4A596D;
    border: 2px solid #CED8E5;
    background-color: transparent;
  }
};

.button-w {
  .l-button {
    max-width: 260px;
    margin-bottom: 12px;
  }
  .btn-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;

    .desc-icon-w {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #3F9DFF;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      
      .desc-icon {
        width: 14px;
        height: 14px;
        color: #FFFFFF;
      }
    }
  }
}

.product-hunt-badge-w {
  height: 32px;
}

.store-link {
  width: 100%;
  max-width: 150px;
  height: 50px;
}