.content-section.bottom {
  margin-bottom: 222px;
  .content-container.bottom {
    margin-right: 89px;
    .content-title.bottom {
      margin-bottom: 16px;
      white-space: pre-line;

      .text-highlight.bottom {
        display: inline;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .content-section.bottom {
    margin-bottom: 120px;
    .content-container.bottom {
      margin-right: 0;
      .content-title.bottom {

      }
    }
  }
}